<!--  -->
<template>
  <div style="background: rgba(190, 190, 190, 0.2); height: 200vh">
    <van-sticky>
      <!-- 盘点类型 -->
      <div class="point_top">
        <i class="el-icon-arrow-left" @click="back()"></i>
        <!-- <div class="point_kind">{{ pointTitle.inventoryTypeName }}</div> -->
        <div class="point_kind">新增采价</div>
        <div class="point_point">…</div>
      </div>
    </van-sticky>
    <!-- 新增采价表单 -->
    <div
      style="
        width: 92%;
        height: 30vw;
        background: white;
        border-radius: 2vw;
        margin-left: 2%;
        margin-top: 2vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        padding: 0 2vw;
        font-size:5vw;
        font-weight:500;
      "
    >
      
      <div style="display:flex;padding: 2vw 0;align-items: center;">
             <div> 填报人：</div>
             <input
          type="text"
          
          style="background: white; margin-left:6.5vw;border: 1px solid rgba(190, 190, 190, 0.7);width: 60vw;height: 7vw;"
          v-model="form.Informant"
        />
          </div>
          <div style="display:flex;padding: 2vw 0;align-items: center;">
             <div>当前日期：</div>
             <input
       
       readonly
         type="text"
         placeholder="日期: (当前日期)"
         style="border: 0; background: white; margin-left: 2vw;border: 1px solid rgba(190, 190, 190, 0.7);width:60vw;height:7vw;"
         :value="date"
         @click="show = true"
       />

       <van-calendar v-model="show" @confirm="onConfirm" />
          </div>
   
    </div>
    <!-- 药品搜索 -->

    <div
      style="
        width: 96%;
        height: 9vw;
        border-radius: 4.5vw;
        display: flex;
        align-items: center;
        background: #fff;
        color: black;
        margin-left: 2%;
        margin-top: 2vw;
      "
    >
      <img
        src="../assets/add/sousuo 拷贝.png"
        alt=""
        style="width: 20px; height: 20px; margin-left: 4vw"
      />
      <input
        type="text"
        placeholder="药品搜索"
        v-model="valueMedcine"
        style="
          margin-left: 2vw;
          border: 0;
          background-color: #fff;
          width: 80%;
          height: 8vw;
        "
      />
      <div style="margin-left: 3vw">
        <div
          @click="showPopup(valueMedcine)"
          style="
            width: 18vw;
            height: 8.5vw;
            border-radius: 0 4.5vw 4.5vw 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 5vw;
          "
        >
          搜索
        </div>

        <van-action-sheet
          v-model="show1"
          position="bottom"
          cancel-text="取消"
          close-on-click-action
        >
          <ul
            v-for="(item, index) in goodsList2"
            :key="index"
            style="font-size: 5vw; padding: 3vw"
          >
            <li
              @click="onSelect(item)"
              style="border-bottom: 1px solid #e7e8e8"
            >
              <span>{{ index + 1 }}.</span>
             {{item.goodsNo}}/{{ item.goodsName }}/{{item.specification}}/{{item.production_unit}}
            </li>
          </ul>
        </van-action-sheet>
      </div>
    </div>
    <!-- 药品信息 -->
    <div v-if="medicDetailsShow">
      <div
        style="

          width: 94%;
          margin-left: 3%;
          border-radius: 3vw;
          margin-top: 2vw;
          height: auto;
        "
      >
        <div 
          style="width: 100%;
            height:10vw;
            background: #4bc4fa;
            border-radius: 2vw 2vw 0 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            color: #fff;
            
          "
        >
          <div>药品</div>
          <div>规格</div>
          <div style="margin-left:3vw">产地</div>
          <div>零售价</div>
          <div>单位</div>
         
        </div>
        <div 
           style="
            width: 100%;
            height: 50%;
            background: white;
            padding:2vw 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 4.5vw;
            border-radius: 0 0 2vw 2vw;
          "
         
        >
          <div style="width: 15vw">
            <el-tooltip placement="top">
              <div slot="content">{{ medicDetails.goodsName }}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 13.5vw">
                {{ medicDetails.goodsName }}
              </div>
            </el-tooltip>
          </div>
          <div style="width: 15vw">
            <el-tooltip placement="top">
              <div slot="content">{{ medicDetails.specification }}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 9vw">
                {{ medicDetails.specification }}
              </div>
            </el-tooltip>
          </div>
          <div style="width: 15vw;margin-right: 2vw;">
            <el-tooltip placement="top">
              <div slot="content">{{ medicDetails.production_unit }}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 9vw">
                {{ medicDetails.production_unit }}
              </div>
            </el-tooltip>
          </div>
          <div style="width: 15vw; margin-right: -5vw">
            <el-tooltip placement="top">
              <div slot="content">{{ medicDetails.retailPrice }}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 5vw">
                {{ medicDetails.retailPrice }}
              </div>
            </el-tooltip>
          </div>
          <div style="width: 15vw; margin-right: -5vw">
            <el-tooltip placement="top">
              <div slot="content">{{ medicDetails.unit }}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 5vw">
                {{ medicDetails.unit }}
              </div>
            </el-tooltip>
          </div>
          <!-- <div style="display: flex">
            <div style="background: green" @click="dialogVisible = true">
              编辑
            </div>
            <div style="background: #ff0000; margin-left: 1vw" @click="delList(index)">删除</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 采购价格 采购店铺  备注 -->
    <div
    v-if="medicDetailsShow"
      style=" width: 92%;
        height:55vw;
        background: white;
        border-radius: 2vw;
        margin-left: 2%;
        margin-top:4vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        padding: 0 2vw;
        font-size:4.5vw;
        font-weight:500;
      "
    >
      
      <div style="display:flex;padding: 2vw 0;align-items: center;">
             <div> 采购价格：</div>
             <input
          type="number"
          
          style="background: white; margin-left:5vw;border: 1px solid rgba(190, 190, 190, 0.7);width: 60vw;height: 7vw;"
          v-model="form.caigouPrice"
        />
          </div>

         <div style="display:flex;padding: 2vw 0;align-items: center;">
             <div> 采购店铺：</div>
             <el-select v-model="value" placeholder="请选择" @change="shopChange(value)" style="width: 62vw;height: 7vw;margin-left:5vw;" size="small">
    <el-option
    
      v-for="item in shopName"
      :key="item.shopName"
      :label="item.shopName"
      :value="item.shopName">
    </el-option>
  </el-select>
             <!-- <input
          type="text"
          
          style="background: white; margin-left:5vw;border: 1px solid rgba(190, 190, 190, 0.7);width: 60vw;height: 7vw;"
          v-model="form.caigouShop"
        /> -->
          </div>

          <div style="display:flex;padding: 2vw 0;align-items: center;">
             <div> 采购备注：</div>
             <input
          type="text"
          
          style="background: white; margin-left:5vw;border: 1px solid rgba(190, 190, 190, 0.7);width: 60vw;height: 7vw;"
          v-model="form.caigouEmo"
        />
          </div>
     <el-button type="primary" style="height:8vw;display:flex;justify-content:center;align-items:center;font-size:5vw;background: #0aabf4;" @click="save()">保存</el-button>
    </div>
    <!-- 药品搜索 ---药品详细信息 -->
    <div  v-if="submitOff">
      <div
        style="
 width: 94%;
          margin-left: 3%;
          border-radius: 3vw;
          margin-top:4vw;
          height: auto;
         
        "
      >
        <div 
          style="
 width: 100%;
            height:10vw;
            background: #4bc4fa;
            border-radius: 2vw 2vw 0 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            color: #fff;
         
          "
        >
          <div>药品</div>
          <div>规格</div>
          <div>店铺</div>
          <div>零售价</div>
          <div>采价</div>
          <div>操作</div>
        </div>
        <div 
           style="
            width: 100%;
            height: 50%;
            background: white;
            padding:2vw 0;
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size:4.5vw;
          "
          v-for="(item, index) in pushList"
          :key="index"
        >
          <div style="width: 15vw;margin-left: 2vw;">
            <el-tooltip placement="top">
              <div slot="content">{{ item.goodsName }}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 9vw">
                {{ item.goodsName }}
              </div>
            </el-tooltip>
          </div>
          <div style="width: 15vw">
            <el-tooltip placement="top">
              <div slot="content">{{ item.specification }}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 9vw">
                {{ item.specification }}
              </div>
            </el-tooltip>
          </div>
          <div style="width: 15vw;margin-right:2vw;">
            <el-tooltip placement="top">
              <div slot="content">{{ item.storeName }}</div>
              <div style="overflow: hidden; white-space: nowrap; width: 13vw">
                {{ item.storeName }}
              </div>
            </el-tooltip>
          </div>
          <div style="width: 15vw;margin-right:2vw;">
            <el-tooltip placement="top">
              <div slot="content">{{  item.retailPrice }}</div>
              <div style="overflow: hidden; white-space: nowrap; width:10vw">
                {{ item.retailPrice }}
              </div>
            </el-tooltip>
          </div>
          <div style="width: 15vw; margin-right: -3vw">
            <el-tooltip placement="top">
              <div slot="content">{{item.inquiryPrice}}</div>
              <div style="overflow: hidden; white-space: nowrap; width:8vw">
                {{item.inquiryPrice }}
              </div>
            </el-tooltip>
          </div>
          <div  style="margin-right:2vw">
            <div style="background: green;width:10vw;font-size:4vw;display: flex;align-items: center;justify-content: center;" @click="dialogV(index,item)">
              编辑
            </div>
            <div style="background: #ff0000;margin-top: 1vw;width:10vw;font-size:4vw;display: flex;align-items: center;justify-content: center; " @click="delList(index)">删除</div>
          </div>
        </div>
      </div>
    </div>
  <!-- 提交按钮 -->
  <div style="display:flex;justify-content:center;margin-top:2vw;" @click="submit()"><van-button style="width:40vw; height: 12vw; border-radius: 6vw;
        background: #0aabf4;
        border: 0;
        margin-left:3vw;
        font-size:6vw;color:white;margin-top: 3vw;">提交</van-button></div>
    <el-dialog
     
      :visible.sync="dialogVisible"
      width="80%"
      height="30vw"
    >
    
        <div>
          <div style="display:flex;padding: 2vw 0;font-size: 5vw;">
             <div> 采购价格：</div>
            <input
              type="text"
              
              style=" background: white;width: 40vw;height: 5vw;border: 1px solid rgba(190, 190, 190, 0.7);"
              v-model="repeatCaigouPrice"
            />
          </div>
          <div style="display:flex;padding: 2vw 0;font-size: 5vw;">
            <div>
              采价店铺：

            </div>
            <input
              type="text"
             
              style=" background: white;width: 40vw;height: 5vw;border: 1px solid rgba(190, 190, 190, 0.7);"
              v-model="repeatCaigouShop"
            />
          </div>
          
        </div>
       
        
       
          
            
          
       
           
            
      
      
     
      <span slot="footer" class="dialog-footer">
       
        <el-button type="primary" @click="aaaEnter()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { time } from 'echarts';


export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        Informant: "", //填报人
        caigouPrice: "", //采购价格
        caigouShop: "", //采购店铺
        caigouEmo: "", //采购备注
      },
      date: "",
      show: false,
      value: "",
      show1: false,
      goodsList2: [],
      // medicDetailsList: [],
      inquiry: {},
      pt: {},
      pushList: [],
      qwaszx:false,
      medicDetails:{},
      medicDetailsShow:false,
      repeatCaigouShop:"",
      repeatCaigouPrice:"",
      submitOff:false,
      shopName:[],
      valueMedcine:""
    
     
    };
  },
  mounted() {
   let date=new Date()
  
      let month=date.getMonth() + 1
      let newMonth=month >=10 ? month : "0" + month
      let day=date.getDate()
      let newDay=day >=10 ? day : "0" + day
    //  this.time=`${date.getFullYear()}-${newMonth}-${newDay}`;
    //  console.log(time,"a")
    console.log(date.getFullYear(),"年")
    console.log(newMonth)
    console.log(newDay)
    let timme=date.getFullYear()+"-"+newMonth+"-"+newDay
    console.log(timme,"a")
    this.date=timme
   this.shop()
  },
  components: {},

  computed: {},

  methods: {
    shopChange(item){
        console.log(item,"店铺")
        localStorage.setItem("item",item)
    },
    shop(){
      
      this.$axios.post(this.$api.inquiryShopList + "?shopName=" + "", {
                // goodsWord:this.medicinal,
                // operator:this.Informant,
                // beginTime:this.query.startTime,
                // endTime:this.query.endTime
            })
            .then((res) => {
            console.log(res,"采购店铺")
            this.shopName=res.data.data
            // this.caijiaWatcList=res.data.data
            // console.log(this.caijiaWatcList,"采价报表")
            });
    },
    save(){
      console.log( localStorage.getItem("item"))
      this.submitOff=true
      let objAdd = JSON.parse(localStorage.getItem("pt1"));
      console.log(objAdd)
      console.log(this.form.Informant)
      console.log(this.date)
      console.log(this.form.caigouEmo)
      console.log(this.form.caigouShop)
      console.log(this.form.caigouPrice)
      console.log(this.medicDetails)
      this.medicDetails.inquiryPrice = this.form.caigouPrice;
      this.medicDetails.storeName = localStorage.getItem("item");
      this.medicDetails.inquiryRemark = this.form.caigouEmo;
      
      this.$delete(this.medicDetails, "unit");
      this.medicDetails.productionUnit=objAdd.production_unit
      this.$delete(this.medicDetails, "production_unit");
      console.log(this.pushList,"是不是空的")
      this.pushList.push(this.medicDetails);
      console.log(this.medicDetails,"aaa")
      
      console.log("数组",this.pushList)
      this.inquiry.inquiryList = this.pushList;
      console.log(this.inquiry,"s")
      this.medicDetailsShow=false
      this.form.caigouPrice = "";
      this.form.caigouShop = "";
      this.form.caigouEmo = "";
      this.value = ""
      localStorage.removeItem("item")
    },
    delList(index){
      this.pushList.splice(index,1)
      // console.log(this.medicDetailsList,"删除之后的")
    },
    aaaEnter() {
      if(this.repeatCaigouPrice==""){
        this.$toast({
          message: "请输入采购价格",
          duration: 1000,
         
        });
        return false
      }
      if(this.repeatCaigouShop==""){
        this.$toast({
          message: "请输入采购店铺",
          duration: 1000,
        });
        return false
      }
      let objAdd = JSON.parse(localStorage.getItem("modify"));
      let a=localStorage.getItem("modify1")
      console.log(a,"dsfee")
      // this.repeatCaigouShop=a.storeName
      // this.repeatCaigouPrice=a.inquiryPrice
      objAdd.storeName=this.repeatCaigouShop
      objAdd.inquiryPrice=this.repeatCaigouPrice
      this.pushList.splice(a,1)
      this.pushList.push(objAdd)
      console.log(objAdd,"aa")
      this.dialogVisible=false
      // let objAdd = JSON.parse(localStorage.getItem("pt1"));
      // if (this.form.caigouPrice == "") {
      //   this.$toast({
      //     message: "请输入采购价格",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      // if (this.form.caigouShop == "") {
      //   this.$toast({
      //     message: "请输入店铺名字",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      // objAdd.inquiryPrice = this.form.caigouPrice;
      // objAdd.storeName = this.form.caigouShop;
      // objAdd.inquiryRemark = this.form.caigouEmo;
      // this.$delete(objAdd, "unit");
      // console.log(objAdd.production_unit,"产地")
      // objAdd.productionUnit=objAdd.production_unit
      // this.$delete(objAdd, "production_unit");
      // // objAdd.productionUnit=item.production_unit
      // // objAdd.storeName=this.user_info.shopName
      // console.log(objAdd,"对象")
      // this.pushList.push(objAdd);
      // console.log(this.pushList, "this.pushList");
      // this.inquiry.inquiryList = this.pushList;

      // this.form.caigouPrice = "";
      // this.form.caigouShop = "";
      // this.form.caigouEmo = "";
      // this.dialogVisible = false;
    },
    // 编辑
    dialogV(tr,td){
      console.log(td,"数据")
      console.log(tr,"小标")
        this.repeatCaigouShop=td.storeName
      this.repeatCaigouPrice=td.inquiryPrice
      let obj = JSON.stringify(td);
      localStorage.setItem("modify",obj)
      localStorage.setItem("modify1",tr)
      // td.storeName=this.repeatCaigouShop
      // td.inquiryPrice=this.repeatCaigouPrice
      console.log(td,"修改数据")
     this.dialogVisible=true
    },
    submit() {
    
      if (this.form.Informant == "") {
        this.$toast({
          message: "请输入填报人",
          duration: 1000,
        });
        return false;
      }
      if (this.date == "") {
        this.$toast({
          message: "请选择日期",
          duration: 1000,
        });
        return false;
      }
      // if(this.medicDetailsList.length==0){
      //   this.$toast({
      //     message: "没有数据不能提交",
      //     duration: 1000,
      //   });
      //   return false;
      // }

      this.user_info = JSON.parse(localStorage.getItem("user_info"));
      this.inquiry.operator = this.form.Informant;
      this.inquiry.inquiryDate = this.date;
      this.inquiry.shopId = this.user_info.userId;

      console.log(this.inquiry, "最后的");

      this.$axios.post(this.$api.savelList, this.inquiry).then((res) => {
        console.log(res,"采价")
        if (res.data.code === 0 || res.data.msg === "success") {
          this.$toast({
            message: "提交成功",
            type: "success",
            duration:1000,
          });
          this.value=""
      this.form.Informant=""
      this.valueMedcine=""
      this.pushList=[]
      this.submitOff=false

        }
        else{
          this.$toast({
            message: res.data.msg,
            duration:1000,
          });
          this.value = ""
          localStorage.removeItem("item")
          return false
        }
        //  console.log(res,"采购")
      });
      
      
     
      // location.reload()
    },
    onSelect(item) {
      console.log(item, "kkkkkkkkkkkkkkkkkk");
      // this.valueMedcine=item.goodsName
      this.medicDetails = item;
      let obj = JSON.stringify(this.medicDetails);
      localStorage.setItem("pt1", obj);
      // this.medicDetailsList.push(this.medicDetails);
      // console.log(this.medicDetailsList, "商品详情");
      this.show1 = false;
      // this.valueMedcine = "";
      // this.informatiobShow = true;
      this.medicDetailsShow=true
    },
    showPopup(valueMedcine) {
      console.log(valueMedcine);
      if (valueMedcine == "") {
        this.$toast({ message: "请输入商品名或货号", duration: 1500 });
        return false;
      }
      this.$axios
        .get(this.$api.searchGoods2DetailList, {
          params: {
            keyWord: valueMedcine,
          },
        })
        .then((res) => {
          console.log(res, "商品名称");
          this.goodsList2 = res.data.data;
          this.goodsListShow = res.data.data;
          console.log(this.goodsList2, "rfgv商品名称");
        });
      this.show1 = true;
      this.informatiobShow = false;
    },
    // 选择日期之后返回的日期格式
    formatDate(date) {
      console.log(date,"日子")
    
      let month=date.getMonth() + 1
      let newMonth=month >=10 ? month : "0" + month
      let day=date.getDate()
      let newDay=day >=10 ? day : "0" + day
      return `${date.getFullYear()}-${newMonth}-${newDay}`;
    },
    // 所选择的日期
    onConfirm(date) {
      this.show = false;
      this.date = this.formatDate(date);
      //   this.data 是日期
      console.log(this.date, "ds");
    },
    back() {
      this.$router.push("./point");
      //   this.$router.push('/point');
    },
  },
};
</script>
<style lang='less' scoped>
</style>
<style>
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight: 600;
}
</style>